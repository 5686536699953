import {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Header from 'components/Common/Header';
import Footer from 'components/Common/Footer';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {CustomCheckBox} from 'utils/CustomInput/CustomCheckBox';
import AppContext from 'components/App/AppContext';
import CustomSpinner from 'utils/Spinner/Spinner';
import {appendPlusToNumber, getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import {getCurrencySymbol, toCurrency} from 'utils/helpers';
import {initializeQrCode} from 'api/routes/wallet';
import {useMutation} from 'react-query';
import CustomPhoneInput from 'utils/CustomInput/CustomPhoneInput';
import {formValidator} from 'utils/FormValidator/FormValidator';

export const QrCodeMultipleForm = ({paymentData}) => {
    const history = useHistory();
    const {metadata: appMetadata} = useContext(AppContext);
    const {reference, API, apiVersion} = paymentData;
    const logo = paymentData?.logo || appMetadata?.company_logo;

    const [selectedAccount, setSelectedAccount] = useState('');
    const [errorMsg, setErrorMsg] = useState();

    const [formValues, setFormValues] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        amount: '',
    });
    const [fieldErrors, setFieldErrors] = useState({});

    const handleChange = ({target: {name, value}}) => {
        handleInput(name, value);
    };

    const handleNumber = ({target: {name, value}}) => {
        value = value.replace(/[^0-9]/g, '');
        handleInput(name, value);
    };

    const handleInput = (name, value) => {
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
        setFieldErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const {mutate, isLoading} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(initializeQrCode(reference, apiVersion), payload, {});
            return response.data;
        },
        {
            onSuccess: data => {
                const link = apiVersion ? '/payments/' : '/';
                if (data.status || data.success) {
                    history.push(`${link}${data?.data?.reference}`);
                } else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error?.response?.data ?? error)),
        }
    );

    const handleSubmit = e => {
        e.preventDefault();
        const payload = {
            ...formValues,
            amount: selectedAccount?.amount || formValues?.amount,
            phone_number: appendPlusToNumber(formValues?.phone_number),
            metadata: {
                SubAccount: selectedAccount?.reference,
            },
            currency: selectedAccount?.currency,
        };

        const {formErrors, isValid} = formValidator(payload);

        isValid
            ? mutate({
                  ...payload,
                  amount: Number(payload?.amount),
              })
            : setFieldErrors(formErrors);
    };

    const disable = !selectedAccount || isLoading;

    return (
        <>
            <Header
                logo={logo}
                twitterLink={paymentData?.twitter}
                instagramLink={paymentData?.instagram}
                facebookLink={paymentData?.facebook}
            />
            <form onSubmit={handleSubmit} className="subscription-content">
                <div className="left-content">
                    <div className="description">
                        <h1 className="head-2">{paymentData?.name}</h1>
                        <p className="description">{paymentData?.description}</p>
                    </div>
                    <div className="d-flex flex-column gap-3">
                        <p className="fw-semibold">Select account to pay into</p>
                        {paymentData?.sub_accounts?.map(account => (
                            <div className="col-12" key={account?.reference}>
                                <CustomCheckBox
                                    cls="mb-0"
                                    label={account?.account_name}
                                    name={account?.reference}
                                    checked={account?.reference === selectedAccount?.reference}
                                    onClick={() => setSelectedAccount(account)}
                                />
                            </div>
                        ))}
                    </div>

                    {(paymentData?.support_email || paymentData?.support_phone_number) && (
                        <div className="info">
                            <p>For more information and inquireries:</p>
                            <div className="contact">
                                <p className="mb-1">{paymentData?.support_email}</p>
                                <p>{paymentData?.support_phone_number}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="right-content">
                    {errorMsg && (
                        <Alert msg={errorMsg} cls="px-5 mt-1 w-100" onClose={() => setErrorMsg()} />
                    )}
                    <h1 className="head-1 mb-2">Payment Details</h1>
                    <div className="w-100">
                        <CustomInputBox
                            required
                            name="first_name"
                            label="First Name"
                            error={fieldErrors?.first_name ? true : false}
                            value={formValues?.first_name}
                            placeholder="Enter First Name"
                            onChange={handleChange}
                        />

                        <CustomInputBox
                            required
                            name="last_name"
                            label="Last Name"
                            error={fieldErrors?.last_name ? true : false}
                            value={formValues?.last_name}
                            placeholder="Enter Last Name"
                            onChange={handleChange}
                        />

                        <CustomPhoneInput
                            required
                            label="Phone Number"
                            name="phone_number"
                            placeholder="Phone Number"
                            onChange={e => handleInput('phone_number', e)}
                            value={formValues?.phone_number}
                            error={fieldErrors?.phone_number ? true : false}
                        />

                        <CustomInputBox
                            required
                            type="email"
                            name="email"
                            label="Email"
                            error={fieldErrors?.email ? true : false}
                            value={formValues?.email}
                            placeholder="Enter Email"
                            onChange={handleChange}
                        />

                        {paymentData?.customer_determine_amount && (
                            <div className="col-12 mt-4">
                                <CustomInputBox
                                    required
                                    type="tel"
                                    name="amount"
                                    label={`Amount ${getCurrencySymbol(selectedAccount?.currency)}`}
                                    placeholder="Enter Amount"
                                    value={formValues?.amount}
                                    onChange={handleNumber}
                                    error={fieldErrors?.amount ? true : false}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-12 pt-3 px-0">
                        <button
                            className="btn btn--lg btn--secondary g-bold"
                            type="submit"
                            disabled={disable}
                        >
                            {isLoading ? <CustomSpinner /> : `Pay`}
                        </button>
                    </div>
                </div>
            </form>
            <Footer logo={logo} poweredBy="Subscriptions" />
        </>
    );
};
