import * as React from 'react';
import './CustomInput.scss';
import {formatIntlNumber} from 'utils/helpers';

const CustomInputBox = ({
    type = 'text',
    name,
    label,
    placeholder,
    value,
    initialValue,
    readonly,
    error,
    icon,
    iconText,
    onChange,
    onFocus,
    required,
    pattern,
    cls,
    inputRef,
    disabled,
    checkAmount,
    isPercentage,
    ...rest
}) => {
    const isError = error ? 'error' : ' ';
    const isRequired = required ? 'required' : ' ';
    const isDisabled = disabled ? 'disabled' : ' ';

    const isAmount = (checkAmount ?? name?.toLowerCase().includes('amount')) && !isPercentage;

    const inputType = isAmount ? 'text' : type;
    const [formattedValue, setFormattedValue] = React.useState('');
    const [inputKey, setInputKey] = React.useState(new Date().getTime());

    React.useEffect(() => {
        if (!isAmount) return;
        const initValue = initialValue ?? value;
        let rawValue = initValue;

        if (initValue) {
            const newValue = Number(initValue).toFixed(2);
            let b = newValue?.toString().replace(/[^0-9]/g, '');
            rawValue = newValue?.toString().replace(/[^0-9]/g, '');
        }
        setFormattedValue(formatIntlNumber(rawValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAmount, initialValue]);

    React.useEffect(() => {
        setInputKey(new Date().getTime());
    }, [isPercentage]);

    const handleChange = ({target}) => {
        let rawValue = target.value.replace(/[^0-9]/g, '');
        let floatValue = rawValue ? parseFloat(rawValue) : rawValue;
        floatValue = floatValue > 0 ? floatValue / 100 : floatValue;
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: floatValue,
                },
            });
        }
        setFormattedValue(formatIntlNumber(rawValue));
    };

    const handleAmountWithPercentage = ({target}) => {
        let rawValue = target.value;

        if (rawValue <= 100) {
            onChange({target: {name, value: rawValue}});
        }
    };

    return (
        <div className={`form-group ${cls}`}>
            {label && (
                <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                    {label}
                </label>
            )}
            <div className={`input-wrapper d-flex align-items-center ${isDisabled} ${isError} `}>
                {icon && <div className="px-4">{icon}</div>}
                <input
                    key={inputKey}
                    type={inputType}
                    id={name}
                    ref={inputRef}
                    name={name}
                    placeholder={placeholder}
                    readOnly={readonly}
                    autoComplete="off"
                    disabled={disabled}
                    pattern={pattern}
                    value={isAmount ? formattedValue : value}
                    onChange={
                        isAmount
                            ? handleChange
                            : isPercentage
                            ? handleAmountWithPercentage
                            : onChange
                    }
                    {...rest}
                />
                {iconText && <div className="icon__text">{iconText}</div>}
            </div>
            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default CustomInputBox;
