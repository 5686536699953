import {useState, useEffect} from 'react';
import {initializePaymentPage} from 'api/routes/wallet';
import {useApps} from 'components/App/AppContext';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import CustomSpinner from 'utils/Spinner/Spinner';
import {appendPlusToNumber, getCurrencySymbol, getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import {formValidator} from 'utils/FormValidator/FormValidator';
import CustomSelect from 'utils/Selects/CustomSelect/CustomSelect';
import {usePaymentPage} from './PaymentPageContainer';
import CustomPhoneInput from 'utils/CustomInput/CustomPhoneInput';

const PageForm = ({paymentData = {}}) => {
    const {reference, API, apiVersion, refetch, currencyCode} = paymentData;
    const currency = getCurrencySymbol(currencyCode);

    const {setCheckoutData} = useApps();
    const {formValues, setFormValues} = usePaymentPage();
    const amount = paymentData?.amount;

    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});

    const plans = paymentData?.products;

    useEffect(() => {
        setFormValues(prev => ({...prev, amount}));
    }, [amount, setFormValues]);

    const handleChange = ({target: {name, value}}) => {
        handleInput(name, value);
    };

    const handleInput = (name, value) => {
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
        setFieldErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();

        const payload = {
            ...formValues,
            amount: +formValues?.amount,
            currency: paymentData?.currency,
        };
        if (paymentData?.page_type === 'recurring') {
            payload.planRef = formValues?.planRef?.reference;
            delete payload.amount;
        }
        if (paymentData?.page_type !== 'product') {
            delete payload.quantity;
        }

        if (paymentData?.page_type !== 'recurring') {
            delete payload.planRef;
        }
        if (!paymentData?.collect_name) {
            delete payload.first_name;
            delete payload.last_name;
        }

        if (!paymentData?.collect_phone) {
            delete payload.phone;
        }
        if (!paymentData?.collect_shipping_dest) {
            delete payload.shipping_dest;
        }
        if (paymentData?.page_type === 'product') {
            if (paymentData?.customer_edit_product_quantity) {
                payload.products_quantity = formValues?.products?.map(product => ({
                    productRef: product?.reference,
                    quantity: product?.quantity,
                }));
                payload.amount = formValues?.products?.reduce(
                    (r, v) => r + v?.amount * v?.quantity,
                    0
                );
            } else {
                payload.amount = formValues?.products?.reduce((r, v) => r + v?.amount, 0);
            }
        }
        delete payload.products;

        const {formErrors, isValid} = formValidator(payload);

        isValid ? initiateCharge(payload) : setFieldErrors(formErrors);
    };

    const initiateCharge = async (payload = {}) => {
        try {
            setLoading(true);
            setErrorMsg();
            const newPayload = {...payload};
            payload.phone && (newPayload.phone = appendPlusToNumber(payload.phone));

            const response = await API.post(
                `${apiVersion}${initializePaymentPage(reference)}`,
                newPayload
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setLoading(false);
                setCheckoutData({
                    url: data?.data?.authorization_url,
                    refetch,
                });
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    const setError = message => {
        setLoading(false);
        setErrorMsg(message);
    };

    return (
        <>
            {errorMsg && <Alert msg={errorMsg} cls="px-5 mt-1" onClose={() => setErrorMsg()} />}
            <h2>Payment Details</h2>
            <form onSubmit={handleSubmit}>
                {paymentData?.page_type !== 'recurring' &&
                    !(
                        paymentData?.page_type === 'product' &&
                        paymentData?.customer_edit_product_quantity == true
                    ) && (
                        <div className="col-12">
                            <CustomInputBox
                                required
                                name="amount"
                                label={`Amount ( ${currency} )`}
                                error={fieldErrors?.amount ? true : false}
                                value={formValues?.amount}
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                disabled={amount > 0}
                                initialValue={amount}
                            />
                        </div>
                    )}

                {paymentData?.collect_name && (
                    <div className="d-flex flex-column flex-md-row">
                        <div className="col-12 col-md-6 pe-md-3">
                            <CustomInputBox
                                label="Firstname"
                                name="first_name"
                                placeholder="Enter Firstname"
                                required
                                error={fieldErrors?.first_name ? true : false}
                                value={formValues?.first_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 col-md-6 ps-md-3">
                            <CustomInputBox
                                label="Lastname"
                                name="last_name"
                                placeholder="Enter Lastname"
                                required
                                error={fieldErrors?.last_name ? true : false}
                                value={formValues?.last_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                )}

                <div className="col-12">
                    <CustomInputBox
                        label="Email"
                        name="email"
                        placeholder="Enter Email"
                        required
                        error={fieldErrors?.email ? true : false}
                        value={formValues?.email}
                        onChange={handleChange}
                    />
                </div>

                {paymentData?.collect_phone && (
                    <div className="col-12">
                        <CustomPhoneInput
                            required
                            label="Phone Number"
                            name="phone"
                            placeholder="Enter Phone Number"
                            onChange={e => handleInput('phone', e)}
                            value={formValues?.phone}
                            error={fieldErrors?.phone ? true : false}
                            countryCodeEditable={false}
                        />
                    </div>
                )}

                {paymentData?.collect_shipping_dest && (
                    <div className="col-12">
                        <CustomInputBox
                            label="Shipping Address"
                            name="shipping_dest"
                            placeholder="Enter your Shipping Address"
                            required
                            error={fieldErrors?.shipping_dest ? true : false}
                            value={formValues?.shipping_dest}
                            onChange={handleChange}
                        />
                    </div>
                )}
                {paymentData?.page_type === 'recurring' && (
                    <div className="col-12">
                        <CustomSelect
                            label={`Plan`}
                            name="planRef"
                            placeholder={`Choose your Plan`}
                            required
                            error={fieldErrors?.planRef ? true : false}
                            value={formValues?.planRef}
                            onChange={value => handleInput('planRef', value)}
                            options={plans}
                            getOptionLabel={item => item?.name}
                            getOptionValue={item => item?.reference}
                        />
                    </div>
                )}

                <div className="col-12 pt-4 mb-4">
                    <button
                        className="btn btn--sm btn--secondary g-bold px-5"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <CustomSpinner /> : 'Pay Now'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default PageForm;
