import {useState} from 'react';
import {useMutation} from 'react-query';
import {useHistory} from 'react-router-dom';
import {initializePaymentLink} from 'api/routes/wallet';
import {getError} from 'utils/helpers';
import {PaymentForm} from 'components/Common/PaymentForm';

export const PaymentLinkForm = ({paymentData = {}}) => {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState();
    const {reference, API, apiVersion} = paymentData;

    const initializeCheckout = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(
                `${apiVersion}${initializePaymentLink(reference)}`,
                payload,
                {}
            );
            return response.data;
        },
        {
            onSuccess: data => {
                const link = apiVersion ? '/payments/' : '/';
                if (data.status || data.success) {
                    history.push(`${link}${data?.data?.reference}`);
                } else setErrorMsg(data?.message);
            },
            onError: error => {
                setErrorMsg(getError(error?.response?.data ?? error));
            },
        }
    );

    return (
        <PaymentForm
            paymentData={paymentData}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            initiateQuery={initializeCheckout}
        />
    );
};
