import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {getQrCode} from 'api/routes/wallet';
import InitializeError from 'components/Checkouts/Checkout/common/InitializeError';
import {CheckoutLayout} from 'components/Checkouts/CheckoutSC';
import {useApps} from 'components/App/AppContext';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {getError, getVersion} from 'utils/helpers';
import {QrCodeForm} from './QrCodeForm';
import {AppMeta} from 'components/App/AppMeta';
import {EXPIRED, IN_ACTIVE} from 'components/constants';
import Layout from 'components/Common/Layout';
import {QrCodeMultipleForm} from './QrCodeMultipleForm';

export const QrCode = ({location}) => {
    const {colors} = useApps();

    const {reference} = useParams();
    const {API, apiVersion} = getVersion(location);
    const [expired, setExpired] = useState(false);

    const {isLoading, data, isError, error, refetch} = useQuery(
        ['get-qr-code', reference],
        async () => {
            try {
                setExpired(false);
                const response = await API.get(getQrCode(reference, apiVersion));
                return response?.data;
            } catch (error) {
                throw error;
            }
        },
        {
            onSuccess: data => {
                const {status} = data?.data ?? {};
                if (status === IN_ACTIVE || status === EXPIRED.toLowerCase()) {
                    setExpired(true);
                }
            },
            enabled: !!reference,
            retry: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const showTransparent = isLoading || isError;
    const payment = data?.data ?? {};
    const pryColor = payment?.primaryColor || colors?.primaryColor;
    const secColor = payment?.secondaryColor || colors?.secondaryColor;

    const renderByStatus = () => {
        if (isLoading)
            return (
                <div className="loader">
                    <BigSpinner />
                </div>
            );
        else if (isError || expired)
            return (
                <InitializeError
                    title={' '}
                    errorMsg={expired ? 'QR Code has expired!' : getError(error)}
                    retry={refetch}
                />
            );

        return payment?.qr_type === 'dynamic-multiple' ? (
            <QrCodeMultipleForm paymentData={{...payment, reference, API, apiVersion}} />
        ) : (
            <QrCodeForm paymentData={{...payment, reference, API, apiVersion}} />
        );
    };

    return (
        <>
            <AppMeta name={payment?.metadata?.merchantName} title="QR Code" />
            {payment?.qr_type === 'dynamic-multiple' ? (
                <Layout
                    colors={{
                        primaryColor: showTransparent ? 'transparent' : pryColor,
                        secColor: showTransparent ? 'transparent' : secColor,
                    }}
                >
                    {renderByStatus()}
                </Layout>
            ) : (
                <CheckoutLayout
                    colors={{
                        primaryColor: showTransparent ? 'transparent' : pryColor,
                        secColor: showTransparent ? 'transparent' : secColor,
                    }}
                    className="checkout"
                >
                    <div className="checkout__container">
                        <div className="checkout__main">{renderByStatus()}</div>
                    </div>
                </CheckoutLayout>
            )}
        </>
    );
};
